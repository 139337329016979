import type { MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";

import H1 from "~/components/H1";

export const meta: MetaFunction = () => [
  { title: "CountLike.PRO - online calculators, generators and  tools" },
  { name: "description", content: "A collection of useful online tools: calculators, generators, converters etc." },

  // https://developers.google.com/search/docs/appearance/site-names?hl=ru#website
  {
    "script:ld+json": {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "CountLike.PRO",
      url: "https://countlike.pro"
    }
  }
];

export default function Index() {
  return (
    <main className="w-full h-full flex flex-col items-center justify-center gap-6 text-center max-w-[900px] mx-auto">
      <H1>Calculators<br /> and other tools for specific use cases</H1>
      <p>This website is a collection of useful online tools: calculators, generators, converters etc.</p>
      <p>For example, if you're filling a visa application form, you might be wondering <Link
        to="/en/dates/count/60-days-from-today">how many days from today is 60 days?</Link>. The <Link
        to="/en/dates/count">"Add or Substract Dates" calculator</Link> will be handy. Or while reading a famous
        novel <Link to="/en/temperature/fahrenheit-to-celsius#fahrenheit-451">Fahrenheit 451</Link> you might be
        wondering <Link to="https://countlike.pro/en/temperature/fahrenheit-to-celsius/451">how many degrees Celsius is
          in 451 °F Fahrenheit</Link>? The <Link to="/en/temperature/fahrenheit-to-celsius">"Fahrenheit to Celsius"
          calculator</Link> will help you.</p>
      <p>Choose your language:</p>
      <div className="flex flex-row flex-wrap justify-center gap-4">
        <Link to="/en">English</Link>
        <Link to="/ru">Русский</Link>
        <Link to="/de">Deutsch</Link>
        <Link to="/fr">Français</Link>
        <Link to="/it">Italiano</Link>
        <Link to="/es">Español</Link>
        <Link to="/pt">Português</Link>
        <Link to="/pl">Polski</Link>
        <Link to="/tr">Türkçe</Link>
        <Link to="/ar">عربى</Link>
      </div>
    </main>
);
}
